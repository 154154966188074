import type { GetStaticPropsContext } from 'next'

import { Layout } from '~/components'
import type { Story } from '~/lib/storyblok'
import { contentTypes } from '~/storyblok/contentTypes'
import { useStoryblok } from '~/lib/storyblok/useStoryblok'
import { storyblok } from '~/storyblok/storyblok'
import { getPaths } from '~/storyblok/getPaths'

import { Page } from '~/contentTypes/Page'
import { Location } from '~/contentTypes/Location'
import { Event } from '~/contentTypes/Event'
import { Chef } from '~/contentTypes/Chef'

type Props = {
  story: Story // TODO: Story or ContentType?
  preview?: boolean
}

export default function Template({ story: initialStory, preview }: Props) {
  const story = useStoryblok(initialStory, { preview, contentTypes })

  return (
    <Layout>
      {story.component === 'Page' && <Page story={story} />}
      {story.component === 'Location' && <Location story={story} />}
      {story.component === 'Event' && <Event story={story} />}
      {story.component === 'Chef' && <Chef story={story} />}
    </Layout>
  )
}

export async function getStaticProps({ params, preview = false }: GetStaticPropsContext) {
  const slug = params?.slug && Array.isArray(params.slug) ? params.slug.join('/') : 'index'
  const story = await storyblok.getStory(slug, { preview })

  if (!story) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      story,
      preview,
    },
    revalidate: 3600,
  }
}

export async function getStaticPaths() {
  const paths = await getPaths(storyblok)

  return {
    paths,
    fallback: false,
  }
}
