import orderBy from 'lodash/orderBy'

import { TransformContext, StoryblokStory } from '~/lib/storyblok'
import { Chef } from './types'
import { relations } from '~/storyblok/relations'
import { transformChef } from './transformChef'

export async function fetchChefs(context: TransformContext): Promise<Chef[]> {
  const stories = await context.client.getAll('cdn/stories', {
    // TODO: correctly load 'draft' or 'published'.
    version: context.preview || process.env.STORYBLOK_FORCE_DRAFT ? 'draft' : 'published',
    resolve_relations: relations?.join(',') || '',
    resolve_links: 'url',
    starts_with: 'koeche/',
    is_startpage: false,
  })

  const chefs: Chef[] = await Promise.all(
    stories.map(async (story: StoryblokStory): Promise<Chef> => {
      return await transformChef(story, context)
    }),
  )

  const sortedChefs = orderBy(chefs, [(chef: Chef) => chef.name.toLowerCase()], ['asc'])

  return sortedChefs
}
