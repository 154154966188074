import Image from 'next/image'
import classNames from 'classnames'

import { Block, Container, OverviewBox } from '~/components'
import type { LocationsOverviewBlock } from './types'

import * as styles from './LocationsOverviewBlock.css'

type Props = {
  block: LocationsOverviewBlock
}

export function LocationsOverviewBlock({ block }: Props) {
  return (
    <Block options={block.options}>
      <Container>
        <div className={styles.events}>
          {block.locations.map((location, index) => (
            <OverviewBox
              key={index}
              title={location.name}
              description={location.teaser}
              image={location.image}
              href={location.path}
              type={location.type}
            />
          ))}
        </div>
      </Container>
    </Block>
  )
}
