import { Block, Container, OverviewBox } from '~/components'
import type { ChefsOverviewBlock } from './types'

import * as styles from './ChefsOverviewBlock.css'

type Props = {
  block: ChefsOverviewBlock
}

export function ChefsOverviewBlock({ block }: Props) {
  return (
    <Block options={block.options}>
      <Container>
        <div className={styles.events}>
          {block.chefs.map((chef, index) => (
            <OverviewBox
              key={index}
              title={chef.name}
              description={chef.teaser}
              image={chef.image}
              href={chef.path}
              type="chef"
            />
          ))}
        </div>
      </Container>
    </Block>
  )
}
