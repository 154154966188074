import Image from 'next/image'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'

import { Block, Container, Heading, RichText, Button } from '~/components'
import type { MediaTextBlock } from './types'

import * as styles from './MediaTextBlock.css'

type Props = {
  block: MediaTextBlock
}

const defaultImageVariants = {
  hidden: {
    opacity: 0,
    x: 0, // <- dynamic (see below)
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: 'spring', stiffness: 600, damping: 100 },
  },
}

export function MediaTextBlock({ block }: Props) {
  const [ref, inView] = useInView({
    rootMargin: '-10px 0px -90px 0px',
  })

  const reverse = block.options.order === 'image_text'

  const imageVariants = {
    ...defaultImageVariants,
    hidden: { ...defaultImageVariants.hidden, x: reverse ? -80 : 80 },
  }

  return (
    <Block options={block.options}>
      <Container>
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          className={reverse ? styles.contentReverse : styles.content}
        >
          <div className={styles.main}>
            <Heading margin="medium">{block.title}</Heading>
            <div className={styles.text}>
              {block.richtext && <RichText content={block.richtext} />}
              {block.cta && (
                <div className={styles.action}>
                  <Button href={block.cta.link.url}>{block.cta.label || block.cta.link.text}</Button>
                </div>
              )}
            </div>
          </div>
          <div className={styles.mediaVariants[block.image.orientation]}>
            <motion.div className={styles.image} variants={imageVariants}>
              <Image src={block.image.url} alt={block.image.alt} layout="fill" objectFit="cover" />
            </motion.div>
          </div>
        </motion.div>
      </Container>
    </Block>
  )
}
