import type { StoryblokBlock, BlockTransformContext } from '~/lib/storyblok'
import type { Chef } from '~/contentTypes/Chef/types'
import type { ChefsOverviewBlock } from './types'
import { fetchChefs } from '~/contentTypes/Chef/fetchChefs'

export async function transformChefsOverviewBlock(
  block: StoryblokBlock,
  { props, options, context }: BlockTransformContext,
): Promise<ChefsOverviewBlock> {
  // const chefs = await Promise.all(
  //   block.chefs.map(async (story: StoryblokStory): Promise<Chef> => {
  //     return await transformChef(story, context)
  //   }),
  // )

  const chefs: Chef[] = await fetchChefs(context)

  return {
    ...props,
    chefs,
    options,
  }
}
