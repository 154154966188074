import React from 'react'
import Image from 'next/image'

import { Block, Container, Heading, RichText, Button } from '~/components'
import type { MediaListBlock } from './types'

import * as styles from './MediaListBlock.css'

type Props = {
  block: MediaListBlock
}

export function MediaListBlock({ block }: Props) {
  return (
    <Block options={block.options}>
      <Container>
        <div className={styles.content}>
          <div className={styles.main}>
            <div className={styles.header}>
              <Heading>{block.title}</Heading>
            </div>
            <div className={styles.lead}>{block.text && <RichText content={block.text} />}</div>
            <div className={styles.text}>
              <dl className={styles.list}>
                {block.items.map((item) => (
                  <React.Fragment key={item.id}>
                    <dt className={styles.listTitle}>{item.title}</dt>
                    <dd className={styles.listText}>
                      <RichText content={item.text} />
                    </dd>
                  </React.Fragment>
                ))}
              </dl>
              {block.cta && (
                <div className={styles.action}>
                  <Button href={block.cta.link.url}>{block.cta.label || block.cta.link.text}</Button>
                </div>
              )}
            </div>
          </div>
          <div className={styles.media}>
            <Image src={block.image.url} alt={block.image.alt} layout="fill" objectFit="cover" />
          </div>
        </div>
      </Container>
    </Block>
  )
}
