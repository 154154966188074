import React, { useRef } from 'react'
import Image from 'next/image'
import classNames from 'classnames'
import { useDraggable } from 'react-use-draggable-scroll'

import { Block } from '~/components'
import type { GalleryBlock } from './types'

import * as styles from './GalleryBlock.css'

type Props = {
  block: GalleryBlock
}

function getOrientationClass(orientation: string): string {
  switch (orientation) {
    case 'landscape':
      return 'imageLandscape'
    case 'portrait':
      return 'imagePortrait'
    default:
      return 'imageSquare'
  }
}

export function GalleryBlock({ block }: Props) {
  const ref = useRef<any>()
  const { events } = useDraggable(ref)

  return (
    <Block options={block.options}>
      <div className={styles.wrapper}>
        <div className={styles.canvas} {...events} ref={ref}>
          <div className={styles.canvasInner}>
            <div className={styles.images}>
              {block.images.map((image) => {
                const orientationClass = getOrientationClass(image.orientation)
                return (
                  <React.Fragment key={image.id}>
                    {/* @ts-ignore:next-line */}
                    <div className={classNames([styles.image, styles[orientationClass]])}>
                      <Image src={image.url} alt={image.alt} layout="fill" objectFit="cover" />
                    </div>
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Block>
  )
}
