import type { StoryblokBlock, BlockTransformContext } from '~/lib/storyblok'
import type { Location } from '~/contentTypes/Location/types'
import type { LocationsOverviewBlock } from './types'
import { fetchLocations } from '~/contentTypes/Location/fetchLocations'

export async function transformLocationsOverviewBlock(
  block: StoryblokBlock,
  { props, options, context }: BlockTransformContext,
): Promise<LocationsOverviewBlock> {
  const locations: Location[] = await fetchLocations(context)

  return {
    ...props,
    locations,
    options,
  }
}
