import type { StoryblokBlock, BlockTransformContext } from '~/lib/storyblok'
import { transformImage } from '~/lib/storyblok'
import type { GalleryBlock } from './types'

export async function transformGalleryBlock(
  block: StoryblokBlock,
  { props, options }: BlockTransformContext,
): Promise<GalleryBlock> {
  const images = block.images.map(transformImage)

  return {
    ...props,
    images,
    options,
  }
}
